import React, { useContext } from 'react'
import { Icon } from '../icon/icon'
import { Grid, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { faFacebookSquare, faInstagram, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { breakpointContext } from './footer'
import Image from 'next/image'
import { SocialLinks } from '../../globals/functions/__generated__/footer'
import { Maybe } from '../../gql'

const SocialIcons = styled(Icon)`
  color: rgba(255, 255, 255, 0.45);
  font-size: ${props => props.theme.typography.pxToRem(34)};
  background: none;

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${props => props.theme.typography.pxToRem(25)};
  }

  &:hover {
    color: ${props => props.theme.palette.secondary.main};
  }
`

const AppStoreLogo = styled.a`
  width: ${props => props.theme.typography.pxToRem(178)};
  margin-left: ${props => props.theme.typography.pxToRem(5)} !important;

  ${props => props.theme.breakpoints.up('lg')} {
    margin-left: ${props => props.theme.typography.pxToRem(30)} !important;
  }

`

const PlayStoreLogo = styled.a`
${props => props.theme.breakpoints.up('md')} {
  margin-right: ${props => props.theme.typography.pxToRem(5)} !important;
  margin-left: ${props => props.theme.typography.pxToRem(0)} !important;
}

${props => props.theme.breakpoints.up('lg')} {
  margin-right: ${props => props.theme.typography.pxToRem(0)} !important;
}

width: ${props => props.theme.typography.pxToRem(178)};
margin-left: ${props => props.theme.typography.pxToRem(10)} !important;
`

const AppImage = styled(Image)`
  border: ${props => props.theme.typography.pxToRem(2)} solid rgba(255, 255, 255, 0.45) !important;
  border-radius: ${props => props.theme.typography.pxToRem(5)};

  &:hover {
    border: ${props => props.theme.typography.pxToRem(2)} solid rgba(255, 255, 255, 0.8) !important;
  }
`

const ConnectHeading = styled(Typography)`
  color: ${props => props.theme.palette.common.white};
  font-size: ${props => props.theme.typography.pxToRem(22)};
  text-align: center;
  margin-top: ${props => props.theme.typography.pxToRem(46)};
  margin-bottom: ${props => props.theme.typography.pxToRem(30)};

  ${props => props.theme.breakpoints.up('lg')} {
    text-align: unset;
    margin-bottom: ${props => props.theme.typography.pxToRem(25)};
    margin-top: ${props => props.theme.typography.pxToRem(117)};
  }
`

const SocialRow = styled(Grid)`
  max-width: ${props => props.theme.typography.pxToRem(340)};
  margin-bottom: ${props => props.theme.typography.pxToRem(39)};

  ${props => props.theme.breakpoints.up('lg')} {
    max-width: ${props => props.theme.typography.pxToRem(235)};
    margin-bottom: ${props => props.theme.typography.pxToRem(28)};
  }
`
const SALogoContainer = styled(Grid)`
  margin-top: ${props => props.theme.typography.pxToRem(25)};
`

export type FooterConnectProp = {
  data?: SocialLinks
}

export const FooterConnect: React.FC<FooterConnectProp> = ({ data }) => { // TODO: Setup links
  const { desktop } = useContext(breakpointContext)

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const getUrlTarget = (target?: Maybe<string> | null): string => {
    return target === '_blank' ? 'blank' : '_self'
  }

  return (
    <Grid
      item
      md={12}
      sm={12}
    >
      <Grid
        container
        direction="column"
        justifyContent={desktop ? 'flex-start':'center'}
        alignItems={desktop ? 'flex-start':'center'}
      >
        <ConnectHeading>Connect</ConnectHeading>
        <SocialRow
          container
          direction="row"
          justifyContent={desktop? 'space-between' : 'space-around'}
          alignItems="center"
        >
          <Grid
            item
          >
            <a rel="noopener noreferrer" target={getUrlTarget(data?.facebook?.target)} href={data?.facebook?.url || '#'}><SocialIcons icon={faFacebookSquare} /></a>
          </Grid>
          <Grid
            item
          >
            <a rel="noopener noreferrer" target={getUrlTarget(data?.twitter?.target)} href={data?.twitter?.url || '#'}><SocialIcons icon={faTwitter} /></a>
          </Grid>
          <Grid
            item
          >
            <a rel="noopener noreferrer" target={getUrlTarget(data?.youtube?.target)} href={data?.youtube?.url || '#'}><SocialIcons icon={faYoutube} /></a>
          </Grid>
          <Grid
            item
          >
            <a rel="noopener noreferrer" target={getUrlTarget(data?.instagram?.target)} href={data?.instagram?.url || '#'}><SocialIcons icon={faInstagram} /></a>
          </Grid>
          <Grid
            item
          >
            <a rel="noopener noreferrer" target={getUrlTarget(data?.linkedin?.target)} href={data?.linkedin?.url || '#'}><SocialIcons icon={faLinkedin} /></a>
          </Grid>
        </SocialRow>
        <Grid
          container
          direction="row"
          justifyContent={desktop ? 'flex-start' : 'center'}
          alignItems="center"
        >
          <PlayStoreLogo rel="noopener noreferrer" target={getUrlTarget(data?.googlePlay?.target)} href={data?.googlePlay?.url || '#'}><AppImage src="/images/appIcons/playStore.svg" alt="" height={63} width={208} /></PlayStoreLogo>
          <AppStoreLogo rel="noopener noreferrer" target={getUrlTarget(data?.appleStore?.target)} href={data?.appleStore?.url || '#'}><AppImage src="/images/appIcons/appStore.svg" alt=""  height={63} width={208} /></AppStoreLogo>
        </Grid>
        <SALogoContainer

        >
          <a rel="noopener noreferrer" target={getUrlTarget(data?.southAustralia?.target)} href={data?.southAustralia?.url || '#'}>
            <Image src="/images/southAusLogo.svg" alt="" height={desktop?  53 : 43} width={desktop? 153 : 125}/>
          </a>
        </SALogoContainer>

      </Grid>
    </Grid>
    
  )
}